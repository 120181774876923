import {SmartComponent} from "smart-component-js";

/**
 * Questo componente molto semplice (da estendere) non fa altro che aspettare che i suoi figli intelligenti si carichino.
 * Se va tutto bene diventa visibile una volta completato il caricamento, se va male si uccide per la disperazione.
 *
 */
class AiBaseGroupComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiBaseGroupComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$cards = this.$el.find('[data-ai]');

        this.init();
    }

    init() {
        this.$cards.on('ai-error', (e) => {
            this.checkCardsStatus();
        });
        this.$cards.on('ai-ready', (e) => {
            let $card = $(e.currentTarget);
            let aiData = $card.data('aiData');

            if (aiData != null) {
                setTimeout(() => {
                    this.printCard(aiData, $card);
                }, 1000);
            }

            this.checkCardsStatus();
        });
    }

    printCard(aiData, $card) {
        let html = `
            <div>${aiData.title}</div>
        `;
        $card.html(html);
    }

    checkCardsStatus() {
        let allDone = true;
        let allGood = true;
        this.$cards.each((i, el) => {
            if ($(el).data('aiStatus') == 'COMPLETED') {

            } else if ($(el).data('aiStatus') == 'ERROR') {
                allGood = false;
            } else {
                allDone = false;
            }
        });

        //Aspetto che tutti i figli sapientoni si siano caricati.
        if (allDone) {
            //Se è andato tutto ok mostro tutto, se invece ci sono errori piallo tutto.
            if (allGood) {
                this.onShow();
            } else {
                this.onRemove();
            }
        }
    }

    onShow() {
        this.$el.show();
    }

    onRemove() {
        this.$el.remove();
    }
}

export default AiBaseGroupComponent;