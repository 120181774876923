import {SmartComponent} from "smart-component-js";

class LandingCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "LandingCtrl";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.isInline = !!this.$el.data('isInline');

        this.init();
    }

    init() {
        if ($('#please-move-me-away').length > 0) {
            let toMove = $('#please-move-me-away').detach();
            toMove.insertBefore('footer.footer');
            toMove.show();
        }

        if (this.isInline) {
            $('[modal-toggle="landing-modal"]').removeAttr('modal-toggle').attr('href', '#landing-form-anchor');
        }
    }
}

export default LandingCtrl;