import {SmartComponentManager} from "../common/smart-component-fix-ie11";
import "../common/custom-event-polyfill";

import DefaultCtrl from "../common/controllers/DefaultCtrl";
import LandingCtrl from "./controllers/LandingCtrl";

import FormContactUsComponent from "./components/FormContactUsComponent";
import FormNabadayComponent from "./components/FormNabadayComponent";
import FormOpenDayComponent from "./components/FormOpenDayComponent";
import FormExhibitionComponent from "./components/FormExhibitionComponent";
import FormSimplicityComponent from "./components/FormSimplicityComponent";
import LandingComponent from "./components/LandingComponent";
import FormNewsletterComponent from "./components/FormNewsletterComponent";
import PageFiltersComponent from "./components/PageFiltersComponent";

import AiNewsComponent from "./components/AiNewsComponent";
import AiCoursesComponent from "./components/AiCoursesComponent";
import AiProjectsComponent from "./components/AiProjectsComponent";
import AiCardComponent from "./components/AiCardComponent";

import TrackingComponent from "../common/components/TrackingComponent";

import "../../less/naba/gge-naba.less";

import AiManager from "../common/business/AiManager";

SmartComponentManager.registerComponents({
    DefaultCtrl,
    LandingCtrl,

    FormContactUsComponent,
    FormOpenDayComponent,
    FormNabadayComponent,
    FormExhibitionComponent,
    FormSimplicityComponent,
    LandingComponent,
    FormNewsletterComponent,
    PageFiltersComponent,

    AiNewsComponent,
    AiCoursesComponent,
    AiProjectsComponent,
    AiCardComponent,

    TrackingComponent
});

$(document).ready(() => {
    function showTooltip() {
        let targetOffset = $('[data-admission-tooltip-target]').offset();
        $('.admission-tooltip').css('top', targetOffset.top).css('left', targetOffset.left).show();
    }

    function hideTooltip() {
        $('.admission-tooltip').hide();
    }

    function updateTimeout(timeoutReference, timeoutAction, time) {
        if (timeoutReference != null) {
            window.clearTimeout(timeoutReference);
        }

        return window.setTimeout(() => {
            timeoutAction();
        }, time);
    }

    if (KD_COMMON_CONSTANTS.LOCALE == 'it_IT') {
        let timeoutAction = showTooltip;
        let timeoutReference = null;
        if ($('.admission-tooltip').length > 0 && !!$('.admission-tooltip').text() && $('[data-admission-tooltip-target]').length > 0) {
            $('[data-admission-tooltip-target], .admission-tooltip').hover(() => {
                timeoutAction = showTooltip;
                timeoutReference = updateTimeout(timeoutReference, timeoutAction, 0);
            }, () => {
                timeoutAction = hideTooltip;
                timeoutReference = updateTimeout(timeoutReference, timeoutAction, 500);
            });
        }
    }

    $('.c-header-gallery[data-component="HeaderGallery"] script').remove();
    $('.c-gallery[data-component="Gallery"] script').remove();
    $(document).on('change', 'select[data-child-valorization]', (e) => {
        let $opt = $(e.currentTarget).find('option:selected');
        $opt.parents('form').find($opt.data('valorizeChild')).val($opt.data('childValue')).change();
    });

    $(document).on('change', '#country-input', function () {
        let $child = $(this).parents('form').find('#province-input');
        if ($child.length > 0) {
            if ("Italy" == $(this).val()) {
                $child.closest('.form-input__province').css('display', 'flex').val(null);
            } else {
                $child.closest('.form-input__province').css('display', 'none').val(null);
            }
        }
    });

    $(document).on('click', '[type="submit"]', function () {
        $('[data-js-name="country"]').each((i, el) => {
            let cCode = $(el).val();
            let $sel = $(el).siblings('[data-js-name="country-real-input"]');
            if ($sel.length > 0) {
                $sel.find('option:selected').prop('selected', false);
                $sel.find('option[data-country-code="' + cCode + '"]').prop('selected', true);
            }
        })
    });

    $(document).on('keydown', '[type="number"]', (e) => {
        if (e.originalEvent.keyCode == 187 ||
            e.originalEvent.keyCode == 188 ||
            e.originalEvent.keyCode == 189 ||
            e.originalEvent.keyCode == 190 ||
            e.originalEvent.keyCode == 69
        ) {
            e.preventDefault();
        }
    });

    $('.social__hasPopup').on('click', function () {
        $('.social__hasPopup').not($(this)).removeClass('visible');
        $(this).toggleClass('visible');
    });

    $(document).on('.footer-upper-newsletter [modal-toggle="newsletter-modal"]').click(() => {
        if (!window.hasTrackedNewsletter) {
            window.hasTrackedNewsletter = true;
            console.log('tracking newsletter modal open');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'virtual_page_newsletter'
            });
        }
    });

    if ($('.c-header-gallery[data-component="HeaderGallery"]').length > 0) {
        $('.c-header-gallery[data-component="HeaderGallery"]').each((index, el) => {
            let $el = $(el);
            let num = $el.find('.glide__slide').length;
            let html = '';

            for (let j = 0; j < num; j++) {
                html += `<button class="glide__bullet" data-glide-dir="=${j}"></button>`;
            }

            $el.find('.glide__bullets').html(html);
        });
    }

    // $(document).on('mouseover', '.desktop-main-menu .desktop-main-menu-level__nav-item:not([data-desktop-level-trigger-id])', (e) => {
    //     let currentLevel = $(e.currentTarget).parents('[data-desktop-level]').data('desktopLevel');
    //     $(e.currentTarget).parents('.desktop-main-menu').find('[data-desktop-level="' + (currentLevel + 1) + '"].active').each((i, el) => {
    //         el.classList.remove('active')
    //     });
    //     $(e.currentTarget).parents('.desktop-main-menu').find('.desktop-main-menu-level__nav-item.active[data-desktop-level-trigger-id]').each((i, el) => {
    //         el.classList.remove('active')
    //     });
    // })

    let $body = $('body');

    if ($body.find('.yxpMainContainer').length > 0) {
        $body = $('.yxpMainContainer:eq(0)');
        if (!$body.attr('component')) {
            $body.attr('component', 'DefaultCtrl');
        }
    }

    if (!!$body && !!$body.attr('component')) {
        SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
    }

    window.kdAiManager = new AiManager();

    const videoContainer = document.getElementsByClassName("yxpType_VIDEO")[0];
    const video = videoContainer && videoContainer.getElementsByTagName("video")[0];
    let scriptInjected = false
    if (video && !scriptInjected) {
        document.getElementsByClassName("yxpType_VIDEO")[0].getElementsByTagName("video")[0].addEventListener("play", () => {
            const rawVideoName = video.getElementsByTagName('source')[0].getAttribute('src')
            const videoName = rawVideoName.replace("\"", '').split('/').pop()
            const gtmScript = generateVideoGTMScript(videoName)
            scriptInjected = injectScript(...gtmScript)

        }, {once: true});
    }
});

const injectScript = (script, code) => {
    try {
        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
    } catch (e) {
        script.text = code;
        document.body.appendChild(script);
    }
    return true;
}

const generateVideoGTMScript = (videoName) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    const code = `window.dataLayer = window.dataLayer || [];
dataLayer.push({
'event': 'video',
'eventCategory': 'video',
'eventAction': 'play',
'eventLabel': '${videoName}'
});`;
    return [script, code]

}