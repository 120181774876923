import {SmartComponent} from "smart-component-js";
import FormUtils from "../common/FormUtils";
import LandingUrlBuilder from "../business/LandingUrlBuilder";

class FormNabadayComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "FormNabadayComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        //Constants
        this.formType = this.$el.data('formType');

        //Real inputs
        this.$degreeLevel = this.$el.find('[name="Degree_Level__c"]');
        this.$course = this.$el.find('[name="Program_Of_Interest__c"]');
        this.$intake = this.$el.find('[name="School_Term__c"]');
        this.$campus = this.$el.find('[name="CampusLocation__c"]');
        this.$campaign = (this.formType == 'NABAVISION' || this.formType == 'NABAINCONTRA') ? false : this.$el.find('[name="Campaign_Source__c"]');
        this.$eventLocation = this.$el.find('[name="event_location_type"]').length > 0 ? this.$el.find('[name="event_location_type"]') : false;

        this.$urlBackField = this.$el.find('[name="redirectURL"]');

        this.$degreeLevelField = this.$el.find('#course-type');

        //Variable values
        this.selectedDegreeLevel = '';
        this.normaAreaToCourses = {};
        this.normalCoursesToCampus = {};

        this.$courseBar = this.$el.find('#course-info-complete');

        this.allowSubmit = false;

        this.init();
    }

    init() {
        this.$el.find('[type="submit"]').click((e) => {
            if (!FormUtils.validateForm(this)) {
                e.preventDefault();
            }
            if (!FormUtils.validateFields(this.$el.find('input:visible, select:visible'))) {
                e.preventDefault();
                $("html, body").stop().animate({scrollTop: this.$el.find('.has-error:eq(0)').offset().top - 120}, 500, 'swing');
            } else if (!this.allowSubmit) {
                e.preventDefault();
                this.allowSubmit = true;
                FormUtils.showLoader(this.$el);

                FormUtils.doCaptcha(e, this.$el, this.$urlBackField, this.formType);
            }
        });
        FormUtils.addFormListeners(this.$el);

        if (this.$eventLocation) {
            this.$eventLocation.change(() => {
                FormUtils.updateIdCampaign(this.$el);
            });
        }

        this.manageNormal();
    }

    manageNormal() {
        this.$degreeLevelField.change((e) => {
            this.normalCoursesToCampus = {};
            this.normaAreaToCourses = {};

            this.$course.val('');
            this.$course.data('courseLabel', null);
            this.$campus.val('');
            if (this.$el.find('#intake-input').length > 0) {
                this.$intake.val('');
            }

            this.$el.find('#course-info-complete #course-input').html(KD_NABA_PAGE_CONSTANTS.EMPTY_COURSE_OPTION);
            if (this.$el.find('#intake-input').length > 0) {
                this.$el.find('#intake-input').html(KD_NABA_PAGE_CONSTANTS.EMPTY_INTAKE_OPTION);
            }

            this.selectedDegreeLevel = this.$degreeLevelField.val();

            if (!this.selectedDegreeLevel) {
                this.$courseBar.hide();
                this.$degreeLevel.val('');
            } else {
                let dlKey = this.$degreeLevelField.find('option:selected').attr('value') || '';
                this.$degreeLevel.val(dlKey);

                FormUtils.showLoader(this.$el);
                FormUtils.loadCourses(this.formType, this.selectedDegreeLevel).then((data) => {
                    let html = KD_NABA_PAGE_CONSTANTS.EMPTY_AREA_OPTION;
                    for (let area in data) {
                        if (!!data[area].result) {
                            html += `<option value="${area}">${area}</option>`;
                            for (let i in data[area].result) {
                                let course = data[area].result[i];
                                if (!this.normaAreaToCourses[area]) {
                                    this.normaAreaToCourses[area] = [];
                                }
                                this.normaAreaToCourses[area].push({
                                    label: course.label,
                                    labelUrl: course.labelUrl,
                                    idSf: course.idSalesforce,
                                    idEdition: course.idEdition
                                });

                                if (!this.normalCoursesToCampus[course.idSalesforce]) {
                                    this.normalCoursesToCampus[course.idSalesforce] = [];
                                }
                                this.normalCoursesToCampus[course.idSalesforce] = {
                                    id: course.idCampus,
                                    idSf: course.campus,
                                    workshopinfo: course.workshopinfo,
                                    workshopinfoCampaign: course.workshopinfoCampaign
                                };
                            }
                            this.$courseBar.show();
                        }
                    }

                    this.$el.find('#course-info-complete #area-input').html(html);
                    FormUtils.hideLoader(this.$el);
                });
            }
        });

        if (this.$degreeLevelField.find('option[data-dl-name]').length == 1) {
            this.$degreeLevelField.val(this.$degreeLevelField.find('option[data-dl-name]').val()).change()
        }

        this.$el.on('change', '#course-info-complete #area-input', (e) => {
            if (this.$el.find('#intake-input').length > 0) {
                this.$el.find('#intake-input').html(KD_NABA_PAGE_CONSTANTS.EMPTY_INTAKE_OPTION);
            }

            this.$el.find('#workshop-info-wrapper').hide();
            this.$course.val('');
            this.$course.data('courseLabel', null);
            this.$campus.val('');
            if (this.$el.find('#intake-input').length > 0) {
                this.$intake.val('');
            }

            let area = $(e.currentTarget).val();
            let html = KD_NABA_PAGE_CONSTANTS.EMPTY_COURSE_OPTION;
            html += `<optgroup label="">`;
            for (let i in this.normaAreaToCourses[area]) {
                let c = this.normaAreaToCourses[area][i];
                html += `<option value="${c.idSf}" data-id-edition="${c.idEdition}" ${!c.labelUrl ? '' : `data-label-url="${c.labelUrl}"`}>${c.label}</option>`;
            }
            html += `</optgroup>`;
            this.$el.find('#course-info-complete #course-input').html(html);
        });

        this.$el.on('change', '#course-info-complete #course-input', (e) => {
            FormUtils.showLoader(this.$el);

            let idCourse = $(e.currentTarget).val();
            this.$course.val(idCourse);
            let selectedOption = $(e.currentTarget).find('option:selected');
            let courseLabel = selectedOption.data('labelUrl');
            if (!courseLabel) {
                courseLabel = selectedOption.text();
            }
            this.$course.data('courseLabel', courseLabel);
            let idEdition = selectedOption.data('idEdition');
            let campus = this.normalCoursesToCampus[idCourse];
            this.$campus.val(campus.idSf);

            this.$el.find('#workshop-info-container').html(campus.workshopinfo);
            if (!!this.$campaign && !!campus.workshopinfoCampaign && !this.$eventLocation) {
                this.$campaign.val(campus.workshopinfoCampaign);
            }
            this.$el.find('#workshop-info-wrapper').css('display', 'flex');

            if (this.$el.find('#intake-input').length > 0) {
                this.$intake.val('');

                FormUtils.loadIntake(campus.id, this.selectedDegreeLevel, this.formType, idEdition).then((data) => {
                    let html = KD_NABA_PAGE_CONSTANTS.EMPTY_INTAKE_OPTION;
                    for (let c in data) {
                        let intake = data[c];
                        html += `<option value="${intake.idsalesforce}">${intake.label}</option>`;
                    }

                    this.$el.find('#intake-input').html(html);
                    FormUtils.hideLoader(this.$el);
                });
            } else {
                FormUtils.hideLoader(this.$el);
            }
        });

        this.$el.on('change', '#course-info-complete #intake-input', (e) => {
            this.$intake.val($(e.currentTarget).val());
        });
    }
}

export default FormNabadayComponent;