import {SmartComponent} from "smart-component-js";
import LandingUrlBuilder from "../business/LandingUrlBuilder";
import FormUtils from "../common/FormUtils";

class FormNewsletterComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "FormNewsletterComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.interval = null;

        //Real inputs
        this.$urlBackField = this.$el.find('[name="redirectURL"]');
        this.$degreeLevel = this.$el.find('[name="Degree_Level__c"]');
        this.$course = this.$el.find('[name="Program_Of_Interest__c"]');
        this.$intake = this.$el.find('[name="School_Term__c"]');
        this.$campus = this.$el.find('[name="CampusLocation__c"]');

        //Constants
        this.formType = this.$el.data('formType');

        this.$degreeLevelField = this.$el.find('#course-type');
        this.$areaField = this.$el.find('#area-input');

        this.allowSubmit = false;

        this.init();
    }

    init() {
        FormUtils.showLoader(this.$el);

        this.interval = setInterval(() => {
            if (this.$el.is(':visible')) {
                clearInterval(this.interval);
                this.imComing();
            }
        }, 500);
    }

    showNormal() {
        FormUtils.showLoader(this.$el);
        this.normaAreaToCourses = {};
        if (!!this.selectedDegreeLevel) {
            FormUtils.loadCourses(this.formType, this.selectedDegreeLevel).then((data) => {
                this.$el.find('#course-info-complete #course-input').html(KD_NABA_PAGE_CONSTANTS.EMPTY_COURSE_OPTION);
                this.$el.find('#intake-input').html(KD_NABA_PAGE_CONSTANTS.EMPTY_INTAKE_OPTION);
                let html = KD_NABA_PAGE_CONSTANTS.EMPTY_AREA_OPTION;
                for (let area in data) {
                    if (!!data[area].result) {
                        html += `<option value="${area}">${area}</option>`;
                        for (let i in data[area].result) {
                            let course = data[area].result[i];
                            if (!!this.normaAreaToCourses[area]) {
                                //Questo serve per associare un solo corso per area, tanto devo prendere il primo.
                                continue;
                            }

                            this.normaAreaToCourses[area] = {
                                label: course.label,
                                labelUrl: course.labelUrl,
                                idSf: course.idSalesforce,
                                idEdition: course.idEdition
                            };
                        }
                    }
                }

                this.$areaField.html(html);
                this.$areaField.parents('.form-input').show();

                FormUtils.hideLoader(this.$el);
            });
        } else {
            this.$areaField.parents('.form-input').hide();
        }
    }

    imComing() {
        this.$degreeLevelField.change(() => {
            this.selectedDegreeLevel = this.$degreeLevelField.val();
            this.$degreeLevel.val(this.selectedDegreeLevel);
            this.showNormal();
        });
        this.$areaField.change((e) => {
            FormUtils.showLoader(this.$el);
            this.$course.val('');
            this.$course.data('courseLabel', null);
            let area = $(e.currentTarget).val();
            let courseInfo = this.normaAreaToCourses[area];
            this.$course.val(courseInfo.idSf);
            FormUtils.loadIntake(this.$campus.val(), this.selectedDegreeLevel, this.formType, courseInfo.idEdition).then((data) => {
                for (let c in data) {
                    let intake = data[c];
                    this.$intake.val(intake.idsalesforce);
                    break;
                }
                FormUtils.hideLoader(this.$el);
            });
        });
        this.$el.find('[type="submit"]').click((e) => {
            let checkValidation;
            $("[form-type='newsletter-form'] input[data-required='true']").each(function () {
                switch (this.type) {
                    case 'checkbox':
                        if (!this.checked) {
                            checkValidation = false;
                            return false;
                        } else {
                            checkValidation = true;
                        }
                        break;
                    default:
                        if (!$(this).val()) {
                            checkValidation = false;
                            return false;
                        } else {
                            checkValidation = true;
                        }
                }
            });

            if (!this.allowSubmit) {
                e.preventDefault();
                this.allowSubmit = true;

                FormUtils.doCaptcha(e, this.$el, this.$urlBackField, this.formType, () => {
                    setTimeout(() => {
                        checkValidation && $(e.currentTarget).click();
                    }, 200);

                    LandingUrlBuilder.updateUrl(this.$el, this.$urlBackField, this.formType);
                    checkValidation && FormUtils.showLoader(this.$el);
                });
            }

        });
        FormUtils.addFormListeners(this.$el);
        FormUtils.hideLoader(this.$el);
    }
}

export default FormNewsletterComponent;