import {SmartComponent} from "smart-component-js";
import NabaUtils from "../business/NabaUtils";

class AiCardComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiCardComponent";
        super(element, parentComponent, params);

        this.$el = $(element);


        this.init();
    }

    init() {
        this.$el.on('ai-error', () => {
            this.$el.remove();
        });
        this.$el.on('ai-ready', () => {
            let aiData = this.$el.data('aiData');
            if (aiData != null) {
                let html = this.$el.html();
                html = html.replaceAll('aicarddesktopimageplaceholder', NabaUtils.removeSpaces(aiData.image) || '');
                html = html.replaceAll('aicardmobileimageplaceholder', NabaUtils.removeSpaces(aiData.imageMobile) || '');
                html = html.replaceAll('aicardpretitleplaceholder', aiData.description2 || '');
                html = html.replaceAll('aicardtitleplaceholder', aiData.title || '');
                html = html.replaceAll('aicardsubtitleplaceholder', aiData.subtitle || '');
                html = html.replaceAll('aicarddescriptionplaceholder', aiData.description1 || '');
                html = html.replaceAll('aicardurlplaceholder', aiData.url || '');
                html = html.replaceAll('aicardbgcolorplaceholder', aiData.bgColor || '');

                if(!!aiData.flag1) {
                    html = html.replaceAll('slide--light', 'slide--dark');
                }

                this.$el.html(html);

                this.$el.css('visibility', 'visible');
                NabaUtils.fixNabaSquare();
            }
        });
    }
}

export default AiCardComponent;