import Utils from "../../common/business/Utils";

class NabaUtils extends Utils {
    fixNabaSquare() {
        setTimeout(() => {
            //Cerco tutti i nabasquare inizializzati
            $('.nabasquare.nabasquare--initialized').each((i, el) => {
                let $el = $(el);
                //Se un nabasquare non ha l'altezza vuol dire che l'immagine non c'è, quindi non ha senso perderci tempo
                //Il campo clip-path polygon funziona prendendo in ingresso 4 coppie di coordinate che indicano gli angoli del clip
                //Di questi angoli ce ne possono essere solamente:
                let nabato = $el.data('nabato');
                let max = 4;
                if (nabato == "topright" || nabato == "bottomleft") {
                    max = 3;
                } else if (nabato == "topleft") {
                    max = 2;
                }

                //Tolgo la parola polygon e tutti i caratteri all'infuori delle coordinate Xpx.
                //Splitto per px in modo da ottenere un array di coordinate pulite
                //Filtro mantenendo solo gli 0
                //Ho il mio totale pulito.
                let effective = $el.css('clip-path').replace('polygon', '').replace(/[^\dpx]/g, '').split('px').filter((x) => {
                    return x == '0'
                }).length;
                if ($el.height() > 10 && effective > max) {
                    $el.removeClass('nabasquare--initialized');
                }
            });

            if ($('.nabasquare:not(.nabasquare--initialized)')) {
                let event = new CustomEvent("reinit-naba-square");
                window.dispatchEvent(event);
            }
        }, 200);
    }
}

export default new NabaUtils();
