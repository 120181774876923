import AiBaseGroupComponent from "../../common/components/AiBaseGroupComponent";
import NabaUtils from "../business/NabaUtils";

class AiProjectsComponent extends AiBaseGroupComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiProjectsComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$cards = this.$el.find('[data-ai]');

        this.init();
    }

    printCard(aiData, $card) {
        let html = `
            <div class="slide__content">
                <div class="slide__pretitle">${aiData.description2 || ''}</div>
                <div class="slide__title">${aiData.title || ''}</div>
                <div class="slide__description">${aiData.description1 || ''}</div>
                <div class="slide__cta">
                    <a href="${aiData.url}" target="" class="btn">
                        ${KD_NABA_SITE_TRANSLATIONS["naba.common.findOutMore"]}
                    </a>
                </div>
            </div>
        `;
        let image = NabaUtils.removeSpaces(aiData.image);
        let imageMobile = aiData.imageMobile ? Utils.removeSpaces(aiData.imageMobile) : image;
        $card.find('source').attr('srcset', image);
        $card.find('img').attr('src', imageMobile);

        $card.find('.slide__wrapper').append(html);
        NabaUtils.fixNabaSquare();
    }
}

export default AiProjectsComponent;