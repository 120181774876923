class LandingUrlBuilder {
    constructor() {
    }

    updateUrl($form, $input, formType) {
        if (!!$input && $input.length > 0) {
            let lingua = 'it';
            let citta = 'generic';
            let country = 'generic';
            let webformname = '';
            let programme = 'generic';
            let course = 'generic';

            if (KD_COMMON_CONSTANTS.LOCALE == 'en_GB') {
                lingua = 'en';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'zh_CN') {
                lingua = 'zh-hans';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'fr_FR') {
                lingua = 'fr';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'it_IT') {
                lingua = 'it';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'es_ES') {
                lingua = 'es';
            }
            if (!!formType) {
                if (formType == 'CONTACT_US') {
                    webformname = 'course_contact_us';
                } else if (formType == 'REQUEST_BROCHURE') {
                    webformname = 'request_brochure';
                } else if (formType == 'BOOKING_ORIENTATION') {
                    webformname = 'campus_visit';
                } else if (formType == 'OPENDAY') {
                    webformname = 'open_day';
                } else if (formType == 'OPENDAYUG') {
                    webformname = 'open_day_ug';
                } else if (formType == 'OPENDAYPG') {
                    webformname = 'open_day_pg';
                } else if (formType == 'NABAVISION') {
                    webformname = 'nabavision';
                } else if (formType == 'NABADAY') {
                    webformname = 'nabaexperience';
                } else if (formType == 'NABAINCONTRA') {
                    webformname = 'nabaincontra';
                } else if (formType == 'NEWSLETTER') {
                    webformname = 'newsletter';
                } else if (formType == 'LANDING') {
                    webformname = 'landing_pages';
                } else if (formType == 'SIMPLICITY_ALUMNI') {
                    webformname = 'alumni_contact';
                } else if (formType == 'SIMPLICITY_AGENCY') {
                    webformname = 'industry_contact';
                } else if (formType == 'EXHIBITION') {
                    webformname = 'exhibition';
                }
            }
            if (!!$form) {
                if ($form.find('[name="province"]').length > 0 && !!$form.find('[name="province"]').val()) {
                    citta = $form.find('[name="province"]').val();
                }
                if ($form.find('[name="Country_of_Recruitment__c"]').length > 0 && !!$form.find('[name="Country_of_Recruitment__c"]').val()) {
                    country = $form.find('[name="Country_of_Recruitment__c"]').val().toLowerCase();
                }
                if ($form.find('[name="Degree_Level__c"]').length > 0 && !!$form.find('[name="Degree_Level__c"]').val()) {
                    programme = $form.find('[name="Degree_Level__c"]').val().toLowerCase();
                }
                if ($form.find('[name="Program_Of_Interest__c"]').length > 0 && !!$form.find('[name="Program_Of_Interest__c"]').val()) {
                    course = $form.find('[name="Program_Of_Interest__c"]').data('courseLabel');
                    if (!course) {
                        course = $form.find('[name="Program_Of_Interest__c"]').val();
                    }

                    course = course.split(' - ')[0];
                }
            }

            let retUrl = `${document.location.protocol}//${document.location.hostname}/${lingua}/${citta}/${country}/${webformname}/${programme}/${course}/thankyou`;
            retUrl = retUrl.toLocaleLowerCase();
            $input.val(retUrl.split(" ").join("-").replace(/[^a-zA-Z0-9-/:._]/g, ""));
        }
    }
}

export default new LandingUrlBuilder();
