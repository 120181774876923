import AiBaseGroupComponent from "../../common/components/AiBaseGroupComponent";
import NabaUtils from "../business/NabaUtils";

class AiCoursesComponent extends AiBaseGroupComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiCoursesComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$cards = this.$el.find('[data-ai]');

        this.init();
    }

    printCard(aiData, $card) {
        let html = `
            <div class="grid-item-feature__image">
                <div class="nabasquare" data-nabaset="topleft" data-nabato="topright"
                     data-nabatrigger="load"
                     data-isinverted="false"
                >
                        <picture>
                            <img src="${NabaUtils.removeSpaces(aiData.image)}" alt="">
                        </picture>
                </div>
            </div>
            <div class="grid-item-feature__content">
                <div>
                    <div class="title">${aiData.title || ''}</div>
                    <div class="subtitle">${aiData.description1 || ''}</div>
                </div>
                <div class="c-header-gallery__ctawrapper">
                    <a href="${aiData.url}" class="btn--stroked-dark">
                        ${KD_NABA_SITE_TRANSLATIONS["naba.common.findOutMore"]}
                    </a>
                </div>
            </div>
        `
        $card.html(html);
        NabaUtils.fixNabaSquare();
    }
}

export default AiCoursesComponent;