import {SmartComponent} from "smart-component-js";

class PageFiltersComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "PageFiltersComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$select = (this.$el).find('select');

        this.$searchInput = (this.$el).find('input[name="keyword"]')
        this.init();
    }

    init() {
        const self = this

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const entries = urlParams.entries()
        const filters = {
            page: this.$el.find('input[name="page"]').val()
        }

        for (const entry of entries) {
            self.prefillSearchInputField(entry[0], entry[1], filters)
            self.prefillSelect(entry[0], entry[1], filters)
        }

        //const initialFilters = {...filters}
        //const initialFilters = Object.assign({}, filters)
        const initialFilters = JSON.parse(JSON.stringify(filters));

        (this.$searchInput).on('keyup', function (e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                self.updateFilters("keyword", $(this).val(), filters)
                self.filteredSearch(initialFilters, filters)
            }
        });

        (this.$select).on('change', (e) => {
            const current = $(e.currentTarget)
            const id = current.attr('id')
            const value = current.val()
            self.updateFilters(id, value, filters)
        })

        $('button#filter-apply').click((e) => {
            e.preventDefault();
            self.updateFilters("keyword", self.$searchInput.val(), filters)
            self.filteredSearch(initialFilters, filters)
        })
    }

    filteredSearch(initialFilters, filters) {
        filters.page = this.$el.find('input[name="page"]').val();

        const urlParams = Object.entries(filters).reduce((newQueryString, item) => {
            return `${newQueryString}${newQueryString === '' ? '' : '&'}${item[0]}=${item[1]}`
        }, '');

        // if (!this.compareFilters(initialFilters, filters)) {
        window.location.search = urlParams
        // }
    }

    updateFilters(key, value, filters) {
        if (value === "") {
            delete filters[key]
        } else {
            filters[key] = value
        }

    }

    prefillSelect(key, value, filters) {
        //if GET param matches a select AND //if the option exists set select prefill
        const $selector = $(`select#${key}`)
        if ($selector.length > 0 && $selector.find('option[value=' + value + ']').length > 0) {
            filters[key] = value
            $selector.val(value)
            return true
        }
        return false
    }

    prefillSearchInputField(key, value, filters) {
        if ((this.$searchInput).length > 0 && key === "keyword") {
            filters[key] = value
            this.$searchInput.val(value)
            this.$searchInput.attr('value', value)
            return true
        }
        return false
    }

    compareFilters(object1, object2) {

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;

    }

}

export default PageFiltersComponent;