import {SmartComponent} from "smart-component-js";
import FormUtils from "../common/FormUtils";
import LandingUrlBuilder from "../business/LandingUrlBuilder";

class FormSimplicityComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "FormSimplicityComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.$urlBackField = this.$el.find('[name="redirectURL"]');

        //Constants
        this.formType = this.$el.data('formType');

        this.allowSubmit = false;

        this.init();
    }

    init() {
        this.$el.find('[type="submit"]').click((e) => {
            if (!FormUtils.validateFields(this.$el.find('input:visible, select:visible'))) {
                e.preventDefault();
                $("html, body").stop().animate({scrollTop: this.$el.find('.has-error:eq(0)').offset().top - 120}, 500, 'swing');
            } else if (!this.allowSubmit) {
                e.preventDefault();
                this.allowSubmit = true;
                FormUtils.showLoader(this.$el);

                FormUtils.doCaptcha(e, this.$el, this.$urlBackField, this.formType);
            }
        });
        FormUtils.addFormListeners(this.$el);
    }

}

export default FormSimplicityComponent;