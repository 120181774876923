import RSVP from "rsvp";
import LandingUrlBuilder from "../business/LandingUrlBuilder";

class FormUtils {
    constructor() {
    }

    doCaptcha(e, $el, $urlBackField, formType, customCallback) {
        try {
            if (typeof window.grecaptcha !== 'undefined') {
                grecaptcha.ready(() => {
                    grecaptcha.execute(KD_NABA_SITE_CONSTANTS.GOOGLE_RECAPTCHA_SECRET_CLIENT, {action: 'submit'}).then((token) => {
                        $el.find('[name="recaptchaToken"]').val(token);

                        if (!customCallback) {
                            this._captchaCallback(e, $el, $urlBackField, formType);
                        } else {
                            customCallback();
                        }
                    });
                });
            } else if (typeof window.TencentCaptcha !== 'undefined') {
                let captcha = new TencentCaptcha(KD_COMMON_CONSTANTS.TENCENT_APP_ID, (res) => {
                    console.log('callback:', res);
                    if (res.ret === 0) {
                        $el.find('[name="recaptchaToken"]').val(res.ticket);
                        $el.find('[name="recaptchaRandom"]').val(res.randstr);
                        if (!customCallback) {
                            this._captchaCallback(e, $el, $urlBackField, formType);
                        } else {
                            customCallback();
                        }
                    }
                }, {});
                captcha.show();
            } else {
                //Errore, il recaptcha non va, il form non potrà essere submittato.
            }
        } catch (error) {
            // Load error. Please call the  load error handling function
            console.log("Captcha error!");
            console.log(error);
        }
    }

    _captchaCallback(e, $el, $urlBackField, formType) {
        LandingUrlBuilder.updateUrl($el, $urlBackField, formType);

        setTimeout(() => {
            $(e.currentTarget).click()
        }, 200);
    }

    loadCourses(formType, degreeLevel, optionalArea, optionalCourse) {
        return new RSVP.Promise((resolve, reject) => {
            let url = `/api/1.0/form/${formType}/courses/${degreeLevel}?site=${KD_COMMON_CONSTANTS.SITE}&locale=${KD_COMMON_CONSTANTS.LOCALE}`;
            if (!!optionalArea) {
                url += '&area=' + optionalArea;
            }
            if (!!optionalCourse) {
                url += '&course=' + optionalCourse;
            }
            $.ajax({
                url: url,
                method: "GET",
                contentType: "application/json",
                dataType: "json"
            }).then((data) => {
                resolve(data.body);
            });
        });
    }

    loadCoursesSummer(formType, degreeLevel, optionalCampus) {
        return new RSVP.Promise((resolve, reject) => {
            let url = `/api/1.0/form/${formType}/courses/${degreeLevel}?site=${KD_COMMON_CONSTANTS.SITE}&locale=${KD_COMMON_CONSTANTS.LOCALE}`;
            if (!!optionalCampus) {
                url += '&campus=' + optionalCampus;
            }
            $.ajax({
                url: url,
                method: "GET",
                contentType: "application/json",
                dataType: "json"
            }).then((data) => {
                resolve(data.body);
            });
        });
    }

    loadIntake(campus, degreeLevel, formType, edition) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: `/api/1.0/form/${formType}/intake?site=${KD_COMMON_CONSTANTS.SITE}&locale=${KD_COMMON_CONSTANTS.LOCALE}&campus=${campus}&degreelevel=${degreeLevel}&edition=${edition}`,
                method: "GET",
                contentType: "application/json",
                dataType: "json"
            }).then((data) => {
                resolve(data.body);
            });
        });
    }

    validateFields($inputs) {
        let valid = true;
        $inputs.each((i, el) => {
            let $input = $(el);

            let isValid = this.toggleElementValidation($input);
            valid = valid && isValid;
        });

        return valid;
    }

    validateForm(formController) {
        if (!!formController.specificCourseId) {
            return true;
        }
        if (!!formController.$degreeLevel.val() && formController.$degreeLevel.val().length > 0) {
            if (!!formController.$course.val() && formController.$course.val().length > 0) {
                if (!!formController.$intake.val() && formController.$intake.val().length > 0) {
                    if (!!formController.$campus.val() && formController.$campus.val().length > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    toggleElementValidation($input) {
        let hasError;
        if ($input.is('.not-required')) {
            hasError = false;
        } else {
            if ($input.is('[type="checkbox"]')) {
                hasError = !$input.is(':checked');
            } else if ($input.is('[type="email"]')) {
                hasError = (!$input.val() || $input.val.length <= 0 || !$input.val().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
            } else {
                hasError = (!$input.val() || $input.val.length <= 0);
            }
        }
        $input.toggleClass('has-error', hasError);
        $input.parents('.form-input').find('.form-input__error.js-form-input__error').toggleClass('active', hasError);
        return !hasError;
    }

    appendTargetFocusing($form) {
        $form.on('keypress', 'input:visible', (e) => {
            if (e.which == 13) {
                $form.find('[type="submit"]').click();
            }
        });
    }

    updatePhone($form) {
        let number = $form.find('[data-js-name="phone_prefix"]').val();
        number += $form.find('[name="phone_number"]').val();
        $form.find('[name="MobilePhone"]').val(number);

        if ($form.find('[data-js-name="country"]').length > 0) {

        }
    }

    updateIdCampaign($form) {
        let idCampaign = $form.find('[data-js-name="event_location_type"]').val();
        $form.find('[name="Campaign_Source__c"]').val(idCampaign);
    }

    showLoader($form) {
        let $submit = $form.find('[type="submit"]');
        if (!$submit.data('originalHtml')) {
            $submit.data('originalHtml', $submit.html());
        }

        $submit.addClass('loading');
        $submit.html(`<div class="microloader"></div>`);
    }

    hideLoader($form) {
        let $submit = $form.find('[type="submit"]');
        $submit.removeClass('loading');
        $submit.html($submit.data('originalHtml'));
    }

    addFormListeners($form) {
        if ($form.find('[name="phone_prefix"]').length > 0) {
            $form.find('[name="phone_prefix"]').change(() => {
                this.updatePhone($form);
            });
        }
        if ($form.find('[name="phone_number"]').length > 0) {
            $form.find('[name="phone_number"]').change(() => {
                this.updatePhone($form);
            });
            $form.find('#phone-number-input').on('keyup change', (e) => {
                $(e.currentTarget).val($(e.currentTarget).val().replace(/\D/g, ''));
                if ($(e.currentTarget).val().length > 11) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 11));
                }
            });
            //usato nella landing e newsletter
            $form.find('#phone-input').on('keyup change', (e) => {
                $(e.currentTarget).val($(e.currentTarget).val().replace(/\D/g, ''));
                if ($(e.currentTarget).val().length > 11) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 11));
                }
            });
        }
        if ($form.find('#name-input').length > 0) {
            $form.find('#name-input').keyup((e) => {
                if ($(e.currentTarget).val().length > 40) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 40));
                }
            });
        }
        if ($form.find('#surname-input').length > 0) {
            $form.find('#surname-input').keyup((e) => {
                if ($(e.currentTarget).val().length > 40) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 40));
                }
            });
        }
        $form.on('change', 'input:visible, select:visible', (e) => {
            let $input = $(e.currentTarget);
            this.toggleElementValidation($input);
        });
        this.appendTargetFocusing($form);
    }
}

export default new FormUtils();
